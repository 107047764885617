// src/utils/connectionTrackerRxjs.js
import { interval, fromEvent, merge } from 'rxjs';
import { map, switchMap, scan, startWith } from 'rxjs/operators';
import connectionService from '../services/conexionService';

class ConnectionTracker {
  /**
   * @param {String} userId - ID del usuario
   * @param {Number} weeklyGoal - Meta semanal en horas (por defecto 5)
   * @param {Number} heartbeatInterval - Intervalo en ms para enviar el heartbeat (por defecto 1 hora = 3600000ms)
   */
  constructor(userId, weeklyGoal = 5, heartbeatInterval = 3600000) {
    this.userId = userId;
    this.weeklyGoal = weeklyGoal;
    this.heartbeatInterval = heartbeatInterval;
    this.connectionTime = 0; // tiempo de conexión en segundos acumulado
    this.init();
  }

  init() {
    // Emite un tick cada segundo (para actualizar el contador en el cliente)
    const tick$ = interval(1000);

    // Observable que detecta el estado "activo": ventana enfocada y visible
    const active$ = merge(
      fromEvent(window, 'focus').pipe(map(() => true)),
      fromEvent(window, 'blur').pipe(map(() => false)),
      fromEvent(document, 'visibilitychange').pipe(map(() => !document.hidden))
    ).pipe(startWith(true));

    // Mientras el usuario esté activo, se acumulan los ticks (segundos)
    this.timerSubscription = active$.pipe(
      switchMap(active => (active ? tick$ : [])),
      scan((total, _) => total + 1, 0)
    ).subscribe(totalSeconds => {
      this.connectionTime = totalSeconds;
    });

    // Enviar heartbeat en lotes cada hora
    this.heartbeatSubscription = interval(this.heartbeatInterval).subscribe(() => {
      this.sendHeartbeat();
    });

    // Enviar un último heartbeat al cerrar la página
    window.addEventListener('beforeunload', this.sendHeartbeat.bind(this));
  }

  async sendHeartbeat() {
    // Solo enviamos si hay algo acumulado
    if (this.connectionTime > 0) {
      const payload = {
        userId: this.userId,
        date: new Date().toISOString(),
        connectionTime: this.connectionTime,
        weeklyGoal: this.weeklyGoal,
      };

      try {
        await connectionService.registerConnection(payload);
        // Reinicia el contador tras enviar el batch
        this.connectionTime = 0;
      } catch (error) {
        console.error('Error enviando heartbeat:', error);
      }
    }
  }

  stop() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
    }
    window.removeEventListener('beforeunload', this.sendHeartbeat.bind(this));
  }
}

export default ConnectionTracker;
