// src/TrackerWrapper.js
import { useEffect } from 'react';
import { useGlobal } from '../context/GlobalContext';
import ConnectionTracker from '../utils/conexionTracker';

const TrackerWrapper = () => {
  const { state } = useGlobal();

  useEffect(() => {
    let tracker;
    if (state.user && state.user.id) {
      tracker = new ConnectionTracker(state.user.id, state.user.weeklyGoal || 5);
    }
    return () => {
      if (tracker) tracker.stop();
    };
  }, [state.user]);

  return null;
};

export default TrackerWrapper;
