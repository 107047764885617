// App.js
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { GlobalProvider, useGlobal } from './context/GlobalContext';
import TrackerWrapper from './components/TrackerWrapper';
import './firebaseConfig';

// Páginas críticas
import OrderConfirmationPage from './components/OrderConfirmationPage';
import CancelPage from './components/CancelPage';
import SinglePlanCheckoutPage from './components/SinglePlanCheckoutPage';

// Importaciones lazy
const LandingPage = lazy(() => import('./components/LandingPage'));
const AppRoutes = lazy(() => import('./components/AppRoutes'));
const Verify = lazy(() => import('./components/Verify'));
const Register = lazy(() => import('./components/Register'));
const Login = lazy(() => import('./components/Login'));
const Reset = lazy(() => import('./components/RessePassword'));
const Terms = lazy(() => import('./components/Terminos&Condiciones'));
const Privacy = lazy(() => import('./components/Privacity'));
const CuerpoSelector = lazy(() =>
  import('./components/Cuerpos').then(module => ({ default: module.default }))
);

// Configuración de rutas
const routeConfig = {
  public: [
    { path: '/landing/:referralCode?', component: LandingPage },
    { path: '/login', component: Login },
    { path: '/reset', component: Reset },
    { path: '/terms', component: Terms },
    { path: '/privacy', component: Privacy },
    { path: '/suscription', component: SinglePlanCheckoutPage },
    { path: '/confirm', component: OrderConfirmationPage },
    { path: '/canceled', component: CancelPage },
    { path: '/verify', component: Verify }  // Agregamos verify como ruta pública
  ],
  auth: [
    { path: '/cuerpo', component: CuerpoSelector, requiredState: 'noCuerpo' },
    { path: '/*', component: AppRoutes, requiredState: 'full' }
  ]
};

// Componente para recuperar el estado del usuario
const StateRecovery = ({ children }) => {
  const { state, dispatch } = useGlobal();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!state.user && token && localStorage.getItem('lastKnownState')) {
      try {
        const savedState = JSON.parse(localStorage.getItem('lastKnownState'));
        if (savedState && savedState.user) {
          dispatch({ type: 'RECOVER_STATE', payload: savedState });
          const savedRoute = localStorage.getItem('lastRoute');
          if (savedRoute && !routeConfig.public.some(route => route.path === location.pathname)) {
            window.location.href = savedRoute;
          }
        }
      } catch (error) {
        console.error('Error recovering state:', error);
        localStorage.removeItem('lastKnownState');
        localStorage.removeItem('lastRoute');
        localStorage.removeItem('token');
        localStorage.removeItem('cuerpo');
      }
    }
  }, []);

  useEffect(() => {
    if (state.user) {
      localStorage.setItem('lastKnownState', JSON.stringify(state));
      localStorage.setItem('lastRoute', location.pathname);
    }
  }, [state, location.pathname]);

  return children;
};

// Componente de carga
const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
  </div>
);

// Componente para manejo de errores
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error('Error in component:', error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="fixed inset-0 flex items-center justify-center bg-white">
          <div className="text-center">
            <h1 className="text-xl font-bold mb-4">Algo salió mal</h1>
            <button
              onClick={() => {
                this.setState({ hasError: false });
                window.location.href = '/';
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Reintentar
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Componente de protección de rutas
const AuthGuard = ({ children, requiredState }) => {
  const { state } = useGlobal();
  const location = useLocation();
  const token = localStorage.getItem('token');

  // Verificar inmediatamente la bandera de redirección a verify
  const shouldRedirectToVerify = localStorage.getItem('redirectToVerify') === 'true';
  if (shouldRedirectToVerify) {
    console.log("Detectada bandera de redirección a /verify");
    // Limpiar la bandera para evitar bucles
    localStorage.removeItem('redirectToVerify');
    // Usar un pequeño retraso para asegurar que la bandera se elimina antes de la redirección
    setTimeout(() => {
      window.location.href = '/verify';
    }, 10);
    // Mostrar spinner mientras se prepara la redirección
    return <LoadingSpinner />;
  }

  console.log("AuthGuard completo:", {
    pathname: location.pathname,
    token: Boolean(token),
    user: Boolean(state.user),
    confirmed: state.user?.confirmed,
    cuerpo: state.user?.cuerpo,
    requiredState
  });

  const isPublicRoute = routeConfig.public.some(route => 
    route.path === location.pathname || location.pathname === '/landing'
  );

  if (isPublicRoute) {
    console.log("Permitiendo acceso a ruta pública");
    return children;
  }
  
  if (!token || !state.user) {
    console.log("Redirigiendo a /landing porque no hay token o usuario");
    return <Navigate to="/landing" replace />;
  }
  
  // Si el usuario ya tiene cuerpo y está intentando acceder a /cuerpo
  if (state.user.cuerpo && location.pathname === '/cuerpo') {
    console.log("Usuario ya tiene cuerpo, redirigiendo a home");
    return <Navigate to="/" replace />;
  }
  
  // Solo redirigir a /cuerpo si requiredState es 'full' y el usuario no tiene cuerpo
  if (!state.user.cuerpo && requiredState === 'full') {
    console.log("Redirigiendo a /cuerpo porque el usuario no tiene cuerpo asignado");
    return <Navigate to="/cuerpo" replace />;
  }
  
  console.log("Permitiendo acceso a ruta protegida");
  return children;
};

// Componente principal de rutas
const AppContent = () => {
  const { state } = useGlobal();
  const location = useLocation();

  // Verificar bandera de redirección al montar el componente
  useEffect(() => {
    const checkRedirectFlag = () => {
      const shouldRedirectToVerify = localStorage.getItem('redirectToVerify') === 'true';
      if (shouldRedirectToVerify && location.pathname !== '/verify') {
        console.log("Detectada bandera de redirección a /verify en AppContent");
        localStorage.removeItem('redirectToVerify');
        window.location.href = '/verify';
      }
    };
    
    checkRedirectFlag();
    
    // Revisar periódicamente la bandera (esto ayuda si hay algún problema con el timing)
    const intervalId = setInterval(checkRedirectFlag, 100);
    return () => clearInterval(intervalId);
  }, [location.pathname]);

  return (
    <Routes>
      {routeConfig.public.map(route => (
        <Route key={route.path} path={route.path} element={<route.component />} />
      ))}
      {routeConfig.auth.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard requiredState={route.requiredState}>
              <route.component />
            </AuthGuard>
          }
        />
      ))}
      <Route path="*" element={<Navigate to={state.user ? '/' : '/landing'} replace />} />
    </Routes>
  );
};

// Componente principal de la aplicación
function App() {
  return (
    <GlobalProvider>
      <ErrorBoundary>
        <Router>
          <StateRecovery>
            <TrackerWrapper />
            <Suspense fallback={<LoadingSpinner />}>
              <AppContent />
            </Suspense>
          </StateRecovery>
        </Router>
      </ErrorBoundary>
    </GlobalProvider>
  );
}

export default App;