import React, { createContext, useContext, useReducer, useEffect } from 'react';
import UserService from '../services/User';
import CuerpoService from '../services/Cuerpo';
import { toast } from 'react-toastify';

const GlobalContext = createContext();

const loadStateFromStorage = () => {
  try {
    const savedState = localStorage.getItem('lastKnownState');
    if (!savedState) return null;
    
    const parsedState = JSON.parse(savedState);
    return {
      user: parsedState?.user || null,
      cuerpo: parsedState?.cuerpo || null
    };
  } catch (error) {
    console.error('Error loading state:', error);
    return null;
  }
};

const saveStateToStorage = (state) => {
  try {
    localStorage.setItem('lastKnownState', JSON.stringify(state));
  } catch (error) {
    console.error('Error saving state:', error);
  }
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case 'RECOVER_STATE':
      return { ...state, ...action.payload };

    case 'SET_USER':
      return { ...state, user: action.payload };

    case 'UPDATE_USER':
      return { ...state, user: { ...state.user, ...action.payload } };

    case 'SET_CUERPO':
      return { ...state, cuerpo: action.payload };

    case 'UPDATE_RETOS_COMPLETADOS': {
        // Asegúrate de que el usuario existe antes de actualizar
        if (!state.user) return state;
        
        return {
          ...state,
          user: {
            ...state.user,
            retosCompletados: action.payload
          }
        };
      }
  
    case 'UPDATE_RETOS_CANJEADOS': {
        // Asegúrate de que el usuario existe antes de actualizar
        if (!state.user) return state;
        
        return {
          ...state,
          user: {
            ...state.user,
            retosCanjeados: action.payload
          }
        };
      }

    case 'UPDATE_PRACTICO_STATUS': {
      const { practicoId, pdfUrl, isApto } = action.payload;
      let updatedPracticosCompleted = [];
      let updatedPracticosAprobados = state.user?.practicosAprobados || [];

      if (state.user?.practicosCompleted) {
        updatedPracticosCompleted = state.user.practicosCompleted.map(practico =>
          practico.practicoId.toString() === practicoId
            ? { ...practico, pdfUrl }
            : practico
        );

        const exists = state.user.practicosCompleted.some(
          practico => practico.practicoId.toString() === practicoId
        );

        if (!exists) {
          updatedPracticosCompleted.push({ practicoId, pdfUrl });
        }
      } else {
        updatedPracticosCompleted = [{ practicoId, pdfUrl }];
      }

      if (isApto) {
        const existsInAprobados = updatedPracticosAprobados.some(
          practico => practico.practicoId.toString() === practicoId
        );

        if (!existsInAprobados) {
          updatedPracticosAprobados.push({
            practicoId,
            fechaAprobacion: new Date()
          });
        }
      } else {
        updatedPracticosAprobados = updatedPracticosAprobados.filter(
          practico => practico.practicoId.toString() !== practicoId
        );
      }

      return {
        ...state,
        user: {
          ...state.user,
          practicosCompleted: updatedPracticosCompleted,
          practicosAprobados: updatedPracticosAprobados
        }
      };
    }

    case 'UPDATE_PRACTICO_COMPLETED': {
      const { practicoId, pdfUrl, isApto } = action.payload;
      let updatedPracticosCompleted = [];
      let updatedPracticosAprobados = [...(state.user?.practicosAprobados || [])];

      if (state.user?.practicosCompleted) {
        updatedPracticosCompleted = state.user.practicosCompleted.map(practico =>
          practico.practicoId.toString() === practicoId
            ? { ...practico, pdfUrl }
            : practico
        );

        const exists = state.user.practicosCompleted.some(
          practico => practico.practicoId.toString() === practicoId
        );

        if (!exists) {
          updatedPracticosCompleted.push({ practicoId, pdfUrl });
        }
      } else {
        updatedPracticosCompleted = [{ practicoId, pdfUrl }];
      }

      if (isApto) {
        const existsInAprobados = updatedPracticosAprobados.some(
          practico => practico.practicoId.toString() === practicoId
        );

        if (!existsInAprobados) {
          updatedPracticosAprobados.push({
            practicoId,
            fechaAprobacion: new Date()
          });
        }
      } else {
        updatedPracticosAprobados = updatedPracticosAprobados.filter(
          practico => practico.practicoId.toString() !== practicoId
        );
      }

      return {
        ...state,
        user: {
          ...state.user,
          practicosCompleted: updatedPracticosCompleted,
          practicosAprobados: updatedPracticosAprobados
        }
      };
    }

    case 'UPDATE_TEST_STATUS': {
      const { testId, pdfUrl, score, categoryId } = action.payload;
      let updatedTestCompleted = [];

      if (state.user?.testCompleted && Array.isArray(state.user.testCompleted)) {
        const existingTestIndex = state.user.testCompleted.findIndex(
          test => String(test?.testId) === String(testId)
        );

        if (existingTestIndex >= 0) {
          updatedTestCompleted = state.user.testCompleted.map(test =>
            String(test?.testId) === String(testId)
              ? { ...test, pdfUrl, score, categoryId }
              : test
          );
        } else {
          updatedTestCompleted = [
            ...state.user.testCompleted,
            { testId, pdfUrl, score, categoryId }
          ];
        }
      } else {
        updatedTestCompleted = [{ testId, pdfUrl, score, categoryId }];
      }

      return {
        ...state,
        user: {
          ...state.user,
          testCompleted: updatedTestCompleted
        }
      };
    }

    case 'LOGOUT':
    // Limpiamos todo el localStorage de una sola vez
    localStorage.removeItem('lastKnownState');
    localStorage.removeItem('cuerpo');
    localStorage.removeItem('lastRoute');
    localStorage.removeItem('token');
    
    // También podemos considerar limpiar sessionStorage por si acaso
    sessionStorage.clear();
  
  // Retornamos estado limpio
    return { user: null, cuerpo: null };
  }
};

export const GlobalProvider = ({ children }) => {
  const savedState = loadStateFromStorage();
  const initialState = {
    user: (savedState && savedState.user) || null,
    cuerpo: (savedState && savedState.cuerpo) || 
            JSON.parse(localStorage.getItem('cuerpo')) || null
  };

  const [state, dispatch] = useReducer(globalReducer, initialState);

  const rehydrateUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch({ type: 'LOGOUT' });
        return;
      }

      const userData = await UserService.getMe();
      if (userData) {
        dispatch({ type: 'SET_USER', payload: userData });
        if (userData.cuerpo) {
          try {
            const cuerpoData = await CuerpoService.getCuerpoById(userData.cuerpo);
            dispatch({ type: 'SET_CUERPO', payload: cuerpoData });
          } catch (error) {
            console.error('Error fetching cuerpo:', error);
            toast.error('Error al cargar datos del cuerpo');
          }
        }
      }
    } catch (error) {
      console.error('Session rehydration failed:', error);
      dispatch({ type: 'LOGOUT' });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      rehydrateUser();
    } else {
      dispatch({ type: 'LOGOUT' });
    }
  }, []);

  useEffect(() => {
    if (state.user) {
      saveStateToStorage(state);
    }
  }, [state]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token' && e.newValue === null) {
        dispatch({ type: 'LOGOUT' });
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const contextValue = {
    state: state || { user: null, cuerpo: null },
    dispatch,
    rehydrateUser,
    isAuthenticated: Boolean(state?.user && localStorage.getItem('token')),
    isConfirmed: state?.user?.confirmed || false,
    hasCuerpo: Boolean(state?.cuerpo),
    recoverState: async () => {
      const savedState = loadStateFromStorage();
      const token = localStorage.getItem('token');
      if (savedState && token) {
        dispatch({ type: 'RECOVER_STATE', payload: savedState });
        return true;
      }
      return false;
    }
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }
  return context;
};