// ./src/services/conexionService.js
import api from '../utils/configAxios';

const connectionService = {
  // Registra o actualiza el log diario de conexión
  registerConnection: async (connectionData) => {
    try {
      const response = await api.post('/conexion', connectionData);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al registrar la conexión';
    }
  },

  // Obtiene el registro diario. Se espera recibir parámetros: userId y date
  getDailyConnection: async (params) => {
    try {
      const response = await api.get('/conexion/daily', { params });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener el registro diario';
    }
  },

  // Obtiene el registro semanal. Se espera recibir parámetros: userId, startDate y endDate
  getWeeklyConnection: async (params) => {
    try {
      const response = await api.get('/conexion/weekly', { params });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener el registro semanal';
    }
  },

  getWeeklyLogs: async (params) => {
    try {
      const response = await api.get('/conexion/weekly/logs', { params });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener los logs semanales';
    }
  },

  // Obtiene el registro mensual. Se espera recibir parámetros: userId, month y year
  getMonthlyConnection: async (params) => {
    try {
      const response = await api.get('/conexion/monthly', { params });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener el registro mensual';
    }
  },

  // Obtiene el registro histórico de conexión. Se espera recibir al menos el parámetro: userId
  getHistoricalConnection: async (params) => {
    try {
      const response = await api.get('/conexion/historico', { params });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener el registro histórico';
    }
  },

  // Actualiza la meta semanal
  updateWeeklyGoal: async (data) => {
    try {
      const response = await api.put('/conexion/weekly-goal', data);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al actualizar la meta semanal';
    }
  },
};

export default connectionService;
